import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { NavHeader } from 'components/nav_header';
import { Footer } from 'components/footer';
import { SEO } from 'components/seo';

const StyledPageDiv = styled.div`
  height: 100vh;
`;

const StyledContainer = styled(Container)`
  margin-top: 140px;
  margin-bottom: 100px;
  height: 100%;
`;

const StyledDiv = styled.div`
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
  border-radius: 10px;
`;

const StorePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <SEO pageTitle="Store" />
      <StyledPageDiv className="d-flex flex-column">
        <NavHeader />
        <div className="main" style={{ flex: 1 }}>
          <div className="wrapper bg-white">
            <div className="content-center">
              <StyledContainer>
                <Row>
                  <Col className="ms-auto me-auto d-flex justify-content-center">
                    <h1 className="title h2 text-center text-lg-start">
                      Merch
                    </h1>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    className="d-flex justify-content-center"
                  >
                    <StyledDiv className="d-flex flex-column bg-white justify-content-center align-items-center mt-5 mt-md-0">
                      <img
                        src="https://items-images-production.s3.us-west-2.amazonaws.com/files/ace49685ba819b8bf1704191fcaae93e5ab8233d/original.png"
                        alt="Sunset Skates Hat"
                        onError={(el) => {
                          el.currentTarget.style.display = 'none';
                        }}
                        className="w-100"
                      />
                      <div className="d-flex flex-column justify-content-center">
                        <h4>Sunset Skates Hat</h4>
                        <h4 className="mb-4">$20.00</h4>
                        <Button
                          className="mb-4"
                          target="_blank"
                          href="https://checkout.square.site/merchant/MC6MC4VKTYEZZ/checkout/ZODEUHY346NYSEPYCRSL4CAI?src=embed"
                        >
                          Buy now
                        </Button>
                      </div>
                    </StyledDiv>
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    className="d-flex justify-content-center"
                  >
                    <StyledDiv className="d-flex flex-column bg-white justify-content-center align-items-center mt-5 mt-md-0">
                      <img
                        src="https://items-images-production.s3.us-west-2.amazonaws.com/files/c2d04da19683fc590bb4f2ced3abc8946848baa5/original.jpeg"
                        alt="Sunset Skates T-Shirt"
                        onError={(el) => {
                          el.currentTarget.style.display = 'none';
                        }}
                        className="w-100"
                      />
                      <div className="d-flex flex-column justify-content-center">
                        <h4>Sunset Skates T-Shirt</h4>
                        <h4 className="mb-4">$15.00</h4>
                        <Button
                          target="_blank"
                          className="mb-4"
                          href="https://checkout.square.site/merchant/MC6MC4VKTYEZZ/checkout/YLDL4ITJ34Y46HRDPLKMPJ3R?src=embed"
                        >
                          Buy now
                        </Button>
                      </div>
                    </StyledDiv>
                  </Col>
                </Row>
              </StyledContainer>
            </div>
          </div>
        </div>
        <Footer />
      </StyledPageDiv>
    </>
  );
};

export default StorePage;
